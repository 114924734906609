.m100{
    margin-top: 50px !important;
}
.exprience__container {
    display: grid;
    grid-template-columns:auto;
    gap: 2rem;
}

.exprience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.exprience__container > div:hover {
    background: transparent;
    border-color: var(--color_primary-variant);
    cursor: default;
}
.exprience__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}
.exprience__content{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    row-gap: 2rem;
}
.exprience__details{
    display: flex;
    gap: 1rem;
}
.exprience__details-icon{
    margin-top: 6px;
    color: var(--color-primary);
}
@media screen and (max-width: 1024px){
.exprience__container{
    grid-template-columns: 1fr;
}
    .exprience__container >div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .exprience__content{
        padding: 1rem;
    }

}
@media screen and (max-width: 600px){
.exprience__container{
    gap: 1rem;
}
    .exprience__container>div{
        width: 100%;
        padding: 2rem 1rem;
    }

}